import React from 'react'
import HeroBgAnimation from '../HeroBgAnimation'
import { HeroContainer, HeroBg, HeroLeftContainer, Img, HeroRightContainer, HeroInnerContainer, TextLoop, Title, Span, SubTitle, ResumeButton } from './Herostyle'
import HeroImg from '../../images/dubai.jpg'
import { bio } from "../../data/constants"
import Typewriter from "typewriter-effect"


const Hero = () => {
  return (
    <div id='about'>
      <HeroContainer>
        <HeroBg> <HeroBgAnimation /></HeroBg>
        <HeroInnerContainer>
          <HeroLeftContainer>
            <Title>Hi, I am <br /> {bio.name}</Title>
            <TextLoop>I am a <Span>
              <Typewriter
                options={{
                  strings: bio.roles,
                  autoStart: true,
                  loop: true,
                }}
              />
            </Span>
            </TextLoop>
            <SubTitle>{bio.description}</SubTitle>
            <ResumeButton href={bio.resume} target='display'>Check Resume</ResumeButton>
          </HeroLeftContainer>
          <HeroRightContainer>
            <Img src={HeroImg} alt="hero-image" />
          </HeroRightContainer>
        </HeroInnerContainer>
      </HeroContainer>
    </div>
  )
}

export default Hero