export const bio = {
    name: "Edin Banda",
    roles: [
        "UI/UX Designer",
        "Web Developer",
    ],
    description:
        "Focused on creating meaningful, user-friendly digital experiences. I blend design and function to solve real problems and connect with users. Explore my work below",
    resume:
        "https://firebasestorage.googleapis.com/v0/b/portfolio-852e0.appspot.com/o/Portfolio%20Edin%20Banda.pdf?alt=media&token=91384426-7dab-4d24-8acc-4255d1fec389",
    linkedin: "https://nl.linkedin.com/in/edin-banda",
};

export const skills = [
    {
        title: "UX Research",
        skills: [
            {
                name: "Communication",
                image:
                    "https://cdn-icons-png.flaticon.com/512/9608/9608796.png",
            },
            {
                name: "Personas",
                image:
                    "https://cdn-icons-png.flaticon.com/512/10754/10754226.png",
            },
            {
                name: "Usability testing",
                image:
                    "https://static-00.iconduck.com/assets.00/usability-testing-icon-512x512-m9bs0juh.png",
            },
            {
                name: "Prototyping",
                image:
                    "https://cdn-icons-png.flaticon.com/512/2421/2421271.png",
            },
            {
                name: "Journey Mapping",
                image:
                    "https://cdn-icons-png.flaticon.com/512/3477/3477430.png",
            },
            {
                name: "Critical thinking",
                image:
                    "https://cdn-icons-png.flaticon.com/512/5759/5759732.png",
            },
            {
                name: "A/B Testing",
                image:
                    "https://cdn-icons-png.flaticon.com/512/7046/7046187.png",
            },
        ],
    },
    {
        title: "Design",
        skills: [
            {
                name: "Adobe XD",
                image:
                    "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c2/Adobe_XD_CC_icon.svg/1200px-Adobe_XD_CC_icon.svg.png",
            },
            {
                name: "Photoshop",
                image:
                    "https://upload.wikimedia.org/wikipedia/commons/thumb/a/af/Adobe_Photoshop_CC_icon.svg/640px-Adobe_Photoshop_CC_icon.svg.png",
            },
            {
                name: "Illustrator",
                image:
                    "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Adobe_Illustrator_CC_icon.svg/1200px-Adobe_Illustrator_CC_icon.svg.png",
            },
            {
                name: "Lightroom",
                image: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Adobe_Photoshop_Lightroom_CC_logo.svg/1200px-Adobe_Photoshop_Lightroom_CC_logo.svg.png",
            },
            {
                name: "Figma",
                image:
                    "https://cdn.sanity.io/images/599r6htc/localized/46a76c802176eb17b04e12108de7e7e0f3736dc6-1024x1024.png?w=804&h=804&q=75&fit=max&auto=format",
            },
        ],
    },

    {
        title: "Web Developing",
        skills: [
            {
                name: "React Js",
                image:
                    "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K",
            },
            {
                name: "Wordpress",
                image:
                    "https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/WordPress_blue_logo.svg/1024px-WordPress_blue_logo.svg.png",
            },
            {
                name: "HTML",
                image: "https://www.w3.org/html/logo/badge/html5-badge-h-solo.png",
            },
            {
                name: "CSS",
                image:
                    "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1452px-CSS3_logo_and_wordmark.svg.png",
            },
            {
                name: "JavaScript",
                image:
                    "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/800px-JavaScript-logo.png",
            },
        ],
    },
];
export const experiences = [
    {
        id: 0,
        img: "https://media.licdn.com/dms/image/v2/D4E0BAQEMBBWWvGZfRA/company-logo_200_200/company-logo_200_200/0/1726134220606/ctouch_logo?e=2147483647&v=beta&t=SciDrzgyal36VvLsX15EJebP9iVQrETkezXredzpsr0",
        role: "UX/UI Designer",
        company: "CTOUCH",
        date: "April 2024 - Jan 2024",
        desc: "Creating and researching designs for interactive displays for educational and corporate purposes.",
        skills: [
            "UX Research",
            "Personas",
            "Usability Testing",
            "Competitive Analysis",
            "Interviews",
            "Journey Mapping",
            "Figma",
        ],
    },
    {
        id: 1,
        img: "https://media.licdn.com/dms/image/C560BAQHVM0sKcHTY4A/company-logo_200_200/0/1631420739923/blueriq_logo?e=2147483647&v=beta&t=9FthNw3uU6y-ucnRA4fsp1PKdmJZl73NxHeClq45n0w",
        role: "UX Designer Intern",
        company: "Blueriq",
        date: "May 2023 - Nov 2023",
        desc: "Creating a Low-Fi Prototype for a Pension application.",
        skills: [
            "UX Research",
            "Personas",
            "Usability Testing",
            "Interviews",
            "Journey Mapping",
            "Figma",
        ],
    },
    {
        id: 2,
        img: "https://media.licdn.com/dms/image/v2/D4E0BAQHDaK7rSTq7lg/company-logo_200_200/company-logo_200_200/0/1718977121554/bdo_international_logo?e=2147483647&v=beta&t=HI6qarh_QBJY0_-EWoT8BcJoq1NbBjiZMu-cV8TMIus",
        role: "UX Researcher",
        company: "BDO",
        date: "April 2022 - September 2022",
        desc: "Creating a design for a platform that helps employees to work faster on their work using Adobe XD and testing it with employees.",
        skills: [
            "Adobe XD",
            "HTML",
            "CSS",
            "JavaScript",
            "Interviews",
            "Usability Testing",
        ],
    },

    {
        id: 3,
        img: "https://media.licdn.com/dms/image/D4E0BAQFaVv1ZzTXOeg/company-logo_200_200/0/1694174838162/pwrdbypim_logo?e=2147483647&v=beta&t=TGixP6drLj19GV0pX4tap7Aqr0h8O6JwHM4SFQXUJbU",
        role: "Frontend Engineer Intern",
        company: "PWRDBYPIM",
        date: "April 2021 - September 2021",
        desc: "Working on the Home website creating it with Wordpress and a new version with NodeJS and creating animations with ThreeJs.",
        skills: [
            "Figma",
            "Wordpress",
            "HTML",
            "CSS",
            "JavaScript",
            "NodeJs",
            "ThreeJs",
        ],
    },
];

export const education = [
    {
        id: 0,
        img: "https://hightechcampus.com/storage/2554/200-FONTYS-LOGO-UK-WIT-RGB.png",
        school: "Fontys University of Applied Science, Eindhoven",
        date: "Oct 2019 - Nov 2023",
        grade: "70%",
        desc: "I completed a 4 year bachelors education in ICT & Media Design finishing with an internship as a UX-Designer. During my studies I also did a minor in Digital Marketing for 6 months. ",
        degree: "Bachelor of Technology - ICT Media Design, UX/UI Design and Research",
    },
    {
        id: 1,
        img: "https://pbs.twimg.com/profile_images/771658260734578689/byOxlCFu_400x400.jpg",
        school: "Rodenborch College, Rosmalen",
        date: "Apr 2015 - Apr 2019",
        grade: "7.5/10",
        desc: "I completed my class 5 year high school education at Rodenborch College, Rosmalen, where I studied Technology & Health.",
        degree: "Havo Diploma, Technology & Health",
    },
    {
        id: 2,
        img: "https://www.christiaanhuygenscollege.nl/Portals/780/CHC_logo_DEF_RGB_NW.jpg?ver=2019-03-13-090928-220",
        school: "Christiaan Huygens College, Eindhoven",
        date: "Apr 2013 - Apr 2015",
        desc: "I spend my first two years at the Christiaan Huygens College before transferring because I played basketball and had to transfer to a school that had a athletics education.",
    },
];


export const projects = [
    {
        id: 9,
        title: "Dutch Pension Mobile App",
        date: "May 2023 - Nov 2023",
        description:
            "For my internship I worked on creating a mobile app that helps people that build up their pension in the Netherlands and are interested in viewing possibilities to grow their pension even more. The prototype is created in Figma and was based on experiments like survey's, interviews and usability tests.",
        image:
            "https://firebasestorage.googleapis.com/v0/b/portfolio-852e0.appspot.com/o/Muckup%20Dagobert.png?alt=media&token=cbb93007-8b2d-4b2f-a5c9-7be4a2941012",
        tags: [
            "Figma",
            "Interviews",
            "Usability Testing",
            "HTML",
            "CSS",
            "JavaScript",
        ],
        category: "mobile app",
        webapp: "https://www.figma.com/proto/mXEUihOvJncSwTJjCaaVuh/Dagobert?page-id=0%3A1&type=design&node-id=601-1255&viewport=-8119%2C-2512%2C0.52&t=kb4EFemMWnx82VjZ-1&scaling=scale-down&starting-point-node-id=601%3A1206&show-proto-sidebar=1&mode=design",
    },
    {
        id: 0,
        title: "Starbucks Loyalty App",
        date: "Apr 2023 - May 2023",
        description:
            "Created a mobile app prototype for Starbucks that works as a loyalty app where users can build uo points (bucks) to eventually get a reward. This was created in Adobe XD and the styling was based on a actual advertisement they were having at the time and the design was based on the Starbucks brand guide.",
        image:
            "https://firebasestorage.googleapis.com/v0/b/portfolio-852e0.appspot.com/o/SBAPP.png?alt=media&token=3b3ada58-4e3e-4240-a8cb-b76a747ec95a",
        tags: ["Usability Testing", "Adobe Photoshop", "Adobe XD"],
        category: "mobile app",
        webapp: "https://xd.adobe.com/view/2ce84b64-046a-4516-a9f1-7f6992118d75-79bb/",
    },
    {
        id: 2,
        title: "Purity Home",
        date: "Nov 2022 - Jan 2023",
        description:
            "Created a website using Shopify for a company that sells gemstones and gemstone products. Creating the images and edited them before posting them to the website.",
        image:
            "https://firebasestorage.googleapis.com/v0/b/portfolio-852e0.appspot.com/o/PurityHome%201.png?alt=media&token=f0c78d3d-87a2-40be-9828-961cded5fedc",
        tags: [
            "Figma",
            "Interviews",
            "Usability Testing",
            "Photoshop Illustrator",
            "Canva",
            "Shopify",
        ],
        category: "web app",
        webapp: "https://purityhome.nl/",
    },
    {
        id: 3,
        title: "BDO Digital Apps",
        date: "May 2022 - Jul 2022",
        description:
            "Creating a design for a platform that helps employees to work faster on their work using Adobe XD and testing it with employees.",
        image:
            "https://firebasestorage.googleapis.com/v0/b/portfolio-852e0.appspot.com/o/MockupBDODA.png?alt=media&token=36d955ac-8f61-4ecb-9b48-f56861a98c05",
        tags: [
            "Figma",
            "Interviews",
            "Usability Testing",
            "Card Sorting",
        ],
        category: "web app",
        webapp: "https://www.figma.com/proto/n1cgyNsZA6DEO58z69J6ZS/BDO-Digital-Apps?node-id=0-1&node-type=canvas&t=QRIpxecyXBGaUmFq-0&scaling=min-zoom&content-scaling=fixed&starting-point-node-id=1%3A68",
    },

    {
        id: 3,
        title: "Wellington Lions Home Page",
        date: "Oct 2024",
        description:
            "Designing a new landing page for the Wellington Lions Rugby team",
        image:
            "https://firebasestorage.googleapis.com/v0/b/portfolio-852e0.appspot.com/o/smartmockups_m3zytbv1.jpg?alt=media&token=e4ab969a-bf16-4374-8f44-1a39d0f89fbc",
        tags: [
            "Figma",,
            "Usability Testing",
            "A/B Testing",
        ],
        category: "web app",
        webapp: "https://www.figma.com/proto/hTIpiFS1jAvnf5I9uNIwl7/Fusion?node-id=29-137&node-type=frame&t=qxNzqh7XeRRAme3x-1&scaling=scale-down&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=29%3A137",
    },


];

export const TimeLineData = [
    { year: 2017, text: "Started my journey" },
    { year: 2018, text: "Worked as a freelance developer" },
    { year: 2019, text: "Founded JavaScript Mastery" },
    { year: 2020, text: "Shared my projects with the world" },
    { year: 2021, text: "Started my own platform" },
];